import { ADD_DEGREE_NAME, DELETE_DEGREE_NAME, GET_ALL_DEGREE_LIST, UPDATE_DEGREE_NAME } from "constants/ApiConstant";
import { deleteRequest, getRequest, patchRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

const { makeAutoObservable, runInAction, toJS } = require("mobx");

class DegreeStore {
  degreeList = [];

  isModalValue = {
    isOpen: false,
    title: "",
    degreeId: "",
    modalType: "",
  };

  isLoadingDegreeList = false;
  isLoadingUpdateDegreeName = false;

  constructor() {
    makeAutoObservable(this);
  }

  getDegreeList = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: GET_ALL_DEGREE_LIST });
        const { data, error: responseError } = getValidDataFromResponse({ response, isWarning: true });

        if (!error && !responseError && isValidArray(data)) {
          this.setDegreeList(data);
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };
  addDegreeName = async ( degreeName) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await postRequest({ url: `${ADD_DEGREE_NAME}`, payload: degreeName });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError && isValidArray(data)) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };
  updateDegreeName = async (degreeId, degreeName) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await patchRequest({ url: `${UPDATE_DEGREE_NAME}/${degreeId}`, payload: degreeName });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError && isValidArray(data)) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };
  deleteDegreeName = async (degreeId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await deleteRequest({ url: `${DELETE_DEGREE_NAME}/${degreeId}` });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  setDegreeList = (data = []) => {
    runInAction(() => {
      this.degreeList = data;
    });
  };
  setIsModalValue = (
    data = {
      isOpen: false,
      title: "",
      degreeId: "",
      modalType: "",
    }
  ) => {
    runInAction(() => {
      this.isModalValue = data;
    });
  };
  setIsLoadingDegreeList = (value = false) => {
    runInAction(() => {
      this.isLoadingDegreeList = value;
    });
  };
  setIsLoadingUpdateDegreeName = (value = false) => {
    runInAction(() => {
      this.isLoadingUpdateDegreeName = value;
    });
  };
}

export default DegreeStore;
