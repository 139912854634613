import { ADD_SPECIALITY_NAME, DELETE_SPECIALITY_NAME, GET_ALL_PATIENT_LIST, GET_ALL_SPECIALITY_LIST, UPDATE_SPECIALITY_NAME } from "constants/ApiConstant";
import { deleteRequest, getRequest, patchRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

const { makeAutoObservable, runInAction, toJS } = require("mobx");

class SpecialityStore {
  specialityList = [];

  isModalValue = {
    isOpen: false,
    title: "",
    specialityId: "",
    modalType: "",
  };

  isLoadingSpecialityList = false;
  isLoadingUpdateSpecialityName = false;

  constructor() {
    makeAutoObservable(this);
  }

  getSpecialityList = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: GET_ALL_SPECIALITY_LIST });
        const { data, error: responseError } = getValidDataFromResponse({ response, isWarning: true });

        if (!error && !responseError && isValidArray(data)) {
          this.setSpecialityList(data);
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };
  addSpecialityName = async ( specialityName) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await postRequest({ url: `${ADD_SPECIALITY_NAME}`, payload: specialityName });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError && isValidArray(data)) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };
  updateSpecialityName = async (specialityId, specialityName) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await patchRequest({ url: `${UPDATE_SPECIALITY_NAME}/${specialityId}`, payload: specialityName });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError && isValidArray(data)) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };
  deleteSpecialityName = async (specialityId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await deleteRequest({ url: `${DELETE_SPECIALITY_NAME}/${specialityId}` });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  setSpecialityList = (data = []) => {
    runInAction(() => {
      this.specialityList = data;
    });
  };
  setIsModalValue = (
    data = {
      isOpen: false,
      title: "",
      specialityId: "",
      modalType: "",
    }
  ) => {
    runInAction(() => {
      this.isModalValue = data;
    });
  };
  setIsLoadingSpecialityList = (value = false) => {
    runInAction(() => {
      this.isLoadingSpecialityList = value;
    });
  };
  setIsLoadingUpdateSpecialityName = (value = false) => {
    runInAction(() => {
      this.isLoadingUpdateSpecialityName = value;
    });
  };
}

export default SpecialityStore;
