import Loading from 'components/shared-components/Loading';
import { Observer } from 'mobx-react';
import { useMainStore } from 'mobxStore/mainStore';
import { Suspense, lazy, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getLocalStorageItems } from 'utils/utilities';
import Views from 'views';

const AppLayout = lazy(() => import('./AppLayout'));
const AuthLayout = lazy(() => import('./AuthLayout'));

const Layouts = () => {

	// const token = useSelector(state => state.auth.token);

	const { globalStore } = useMainStore()

	const blankLayout = useSelector(state => state.theme.blankLayout);
	const direction = useSelector(state => state.theme.direction);
	const navType = useSelector(state => state.theme.navType);
	const navCollapsed = useSelector(state => state.theme.navCollapsed);

	// const Layout = token && !blankLayout ? AppLayout : AuthLayout

	useEffect(() => {
		const { setIsLoadingUserAccessToken, setLoginUserAccessToken } = globalStore
		setIsLoadingUserAccessToken(true)
		const { token } = getLocalStorageItems({ get: true, key: 'token' })
		setLoginUserAccessToken(token)
	}, [])

	return (
		<Observer>
			{() => {
				const { loginUserAccessToken: token } = globalStore

				const Layout = token && !blankLayout ? AppLayout : AuthLayout

				return (
					<Suspense fallback={<Loading cover="content" />}>
						<Layout direction={direction} navType={navType} navCollapsed={navCollapsed}>
							<Views />
						</Layout>
					</Suspense>
				)
			}}
		</Observer>
	)
}

export default memo(Layouts)