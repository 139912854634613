import { USER_LOGIN } from "constants/ApiConstant";
import { makeAutoObservable, runInAction } from "mobx";
import { postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

class GlobalStore {

    loginUserAccessToken = ""

    isLoadingUserAccessToken = true
    isLogInLoader = false

    constructor() {
        makeAutoObservable(this);
    }

    adminUserLogin = async (values) => {
        return new Promise(async (resolve, reject) => {
            try {
                const { error: apiError, response } = await postRequest({ url: USER_LOGIN, payload: values });
                const { data, error: responseError } = getValidDataFromResponse({ response, isWarning: true });

                if (!apiError && !responseError && isValidArray(data)) {
                    return resolve({ success: true, data: data })
                }

                return resolve({ success: false, data: [] })

            } catch (error) {
                reject({ success: false, data: [], message: error.message })
            }
        })
    }


    setIsLogInLoader = (value = false) => {
        runInAction(() => {
            this.isLogInLoader = value
        })
    }

    setLoginUserAccessToken = (token = "") => {
        runInAction(() => {
            this.loginUserAccessToken = token
        })
    }

    setIsLoadingUserAccessToken = (value = false) => {
        runInAction(() => {
            this.isLoadingUserAccessToken = value
        })
    }


}

export default GlobalStore;
