import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() => import("views/auth-views/authentication/login")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "dashboards-doctors",
    path: `${APP_PREFIX_PATH}/dashboards/doctors`,
    component: React.lazy(() => import("views/app-views/doctorViews/index")),
  },
  {
    key: "dashboards-doctors-details",
    path: `${APP_PREFIX_PATH}/dashboards/doctor/details`,
    component: React.lazy(() => import("views/app-views/doctorViews/doctor-details")),
  },
  {
    key: "dashboards-patients",
    path: `${APP_PREFIX_PATH}/dashboards/patients`,
    component: React.lazy(() => import("views/app-views/patientViews/index")),
  },
  {
    key: "dashboards-patient-details",
    path: `${APP_PREFIX_PATH}/dashboards/patient/details`,
    component: React.lazy(() => import("views/app-views/patientViews/patientDetails")),
  },
  {
    key: "dashboards-speciality",
    path: `${APP_PREFIX_PATH}/dashboards/speciality`,
    component: React.lazy(() => import("views/app-views/speciality")),
  },
  {
    key: "dashboards-payout",
    path: `${APP_PREFIX_PATH}/dashboards/payout`,
    component: React.lazy(() => import("views/app-views/payout")),
  },
  {
    key: "dashboards-degree",
    path: `${APP_PREFIX_PATH}/dashboards/degree`,
    component: React.lazy(() => import("views/app-views/degree")),
  },
  {
    key: "dashboards-settings",
    path: `${APP_PREFIX_PATH}/dashboards/settings`,
    component: React.lazy(() => import("views/app-views/settings")),
  },
  {
    key: "dashboards-settings",
    path: `${APP_PREFIX_PATH}/dashboards/settings/*`,
    component: React.lazy(() => import("views/app-views/settings/")),
  },
];
