import { ADMIN_GET_PAYOUT_LIST, ADMIN_ROLLOUT_PAYMENT, GET_ALL_DOCTORS_LIST, GET_DOCTOR_APPOINTMENT_LIST, GET_DOCTOR_AVAILABLE_BALANCE, GET_DOCTOR_DETAILS, GET_DOCTOR_PAYOUT_LIST } from "constants/ApiConstant";
import { getRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

const { makeAutoObservable, runInAction } = require("mobx");

class PayoutStore {
  payoutModalValue = {
    isOpen: false,
    doctorId: "",
  };

  payoutList = [];
  doctorPayoutList = [];
  doctorAvailableBalance = [];

  isLoadingForPayoutList = true;
  isLoadingForDoctorPayoutList = true;
  isLoadingForRollOut = false;
  constructor() {
    makeAutoObservable(this);
  }

  adminRollout = async (doctorId, paymentDetails) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await postRequest({ url: `${ADMIN_ROLLOUT_PAYMENT}/${doctorId}`, payload: paymentDetails });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError && isValidArray(data)) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };
  getPayoutList = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: `${ADMIN_GET_PAYOUT_LIST}` });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidArray(data)) {
          this.setPayoutList(data);
          return resolve({ success: true, data: data });
        }
        this.setPayoutList([]);
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setPayoutList([]);
        return reject({ success: false, data: [] });
      }
    });
  };
  getPayoutListForDoctor = async (doctorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: `${GET_DOCTOR_PAYOUT_LIST}/${doctorId}` });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidArray(data)) {
          this.setDoctorPayoutList(data);
          return resolve({ success: true, data: data });
        }
        this.setDoctorPayoutList([]);
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setDoctorPayoutList([]);
        return reject({ success: false, data: [] });
      }
    });
  };
  getDoctorAvailableBalance = async (doctorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: `${GET_DOCTOR_AVAILABLE_BALANCE}/${doctorId}` });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidArray(data)) {
          this.setDoctorAvailableBalance(data);
          return resolve({ success: true, data: data });
        }
        this.setDoctorAvailableBalance([]);
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setDoctorAvailableBalance([]);
        return reject({ success: false, data: [] });
      }
    });
  };

  setPayoutModalValue = (data = { isOpen: false, doctorId: "" }) => {
    runInAction(() => {
      this.payoutModalValue = data;
    });
  };

  setPayoutList = (data = []) => {
    runInAction(() => {
      this.payoutList = data;
    });
  };
  setDoctorPayoutList = (data = []) => {
    runInAction(() => {
      this.doctorPayoutList = data;
    });
  };
  setDoctorAvailableBalance = (data = []) => {
    runInAction(() => {
      this.doctorAvailableBalance = data;
    });
  };

  // loading
  setIsLoadingForRollOut = (loading = false) => {
    runInAction(() => {
      this.isLoadingForRollOut = loading;
    });
  };
  setIsLoadingForPayoutList = (loading = false) => {
    runInAction(() => {
      this.isLoadingForPayoutList = loading;
    });
  };
  setIsLoadingForDoctorPayoutList = (loading = false) => {
    runInAction(() => {
      this.isLoadingForDoctorPayoutList = loading;
    });
  };
}

export default PayoutStore;
