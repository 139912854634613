import { createContext, useContext } from "react";
import DoctorStore from "views/app-views/doctorViews/doctor.store";
import GlobalStore from "./globalStore";
import PatientStore from "views/app-views/patientViews/patient.store";
import SpecialityStore from "views/app-views/speciality/speciality.store";
import DegreeStore from "views/app-views/degree/degree.store";
import Dashboard from "views/app-views/components/feedback/progress/Dashboard";
import DashboardStore from "views/app-views/dashboards/default/dashboard.store";
import AdminSettingStore from "views/app-views/settings/admin-setting.store";
import PayoutStore from "views/app-views/payout/payout.store";

class MainStore {
  constructor() {
    this.globalStore = new GlobalStore(this);
    this.doctorStore = new DoctorStore(this);
    this.patientStore = new PatientStore(this);
    this.specialityStore = new SpecialityStore(this);
    this.degreeStore = new DegreeStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.adminSettingStore = new AdminSettingStore(this);
    this.payoutStore = new PayoutStore(this);

  }

  resetMainStore = () => {
    this.globalStore = new GlobalStore(this);
    this.doctorStore = new DoctorStore(this);
    this.patientStore = new PatientStore(this);
    this.specialityStore = new SpecialityStore(this);
    this.degreeStore = new DegreeStore(this);
    this.dashboardStore = new DashboardStore(this);
    this.adminSettingStore = new AdminSettingStore(this);
    this.payoutStore = new PayoutStore(this);


  };
}

export const mainStore = new MainStore();

const storeContext = createContext({});

export const StoreProvider = storeContext.Provider;

export const useMainStore = () => useContext(storeContext);
