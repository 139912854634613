import { DELETE_USER, GET_ALL_DOCTORS_LIST, GET_DOCTOR_APPOINTMENT_LIST, GET_DOCTOR_DETAILS } from "constants/ApiConstant";
import { deleteRequest, getRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

const { makeAutoObservable, runInAction } = require("mobx");

class DoctorStore {
  allDoctorsList = [];
  doctorDetailsData = [];
  doctorAppointmentList = [];
  isLoadingDoctorsList = false;
  isLoadingDoctorDetailsData = true;
  isLoadingAppointmentList = true;
  isDeleteUserLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getAllDoctorsList = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: GET_ALL_DOCTORS_LIST });
        const { data, error: responseError } = getValidDataFromResponse({ response, isWarning: true });

        if (!error && !responseError && isValidArray(data)) {
          this.setAllDoctorsList(data);
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  getDoctorDetailsData = async (doctorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: `${GET_DOCTOR_DETAILS}/${doctorId}` });
        const { data, error: responseError } = getValidDataFromResponse({ response, isWarning: true });

        if (!error && !responseError && isValidArray(data)) {
          this.setDoctorDetailsData(data);
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  getDoctorAppointmentList = async (doctorId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: `${GET_DOCTOR_APPOINTMENT_LIST}/${doctorId}` });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidArray(data)) {
          this.setDoctorAppointmentList(data);
          return resolve({ success: true, data: data });
        }
        this.setDoctorAppointmentList();

        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setDoctorAppointmentList();
        return reject({ success: false, data: [] });
      }
    });
  };
  deleteUser = async (userId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await deleteRequest({ url: `${DELETE_USER}/${userId}` });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError && isValidArray(data)) {
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  setAllDoctorsList = (data = []) => {
    runInAction(() => {
      this.allDoctorsList = data;
    });
  };

  setDoctorDetailsData = (data = []) => {
    runInAction(() => {
      this.doctorDetailsData = data;
    });
  };
  setDoctorAppointmentList = (data = []) => {
    runInAction(() => {
      this.doctorAppointmentList = data;
    });
  };
  setIsLoadingDoctorsList = (value = false) => {
    runInAction(() => {
      this.isLoadingDoctorsList = value;
    });
  };

  setIsLoadingDoctorDetailsData = (value = false) => {
    runInAction(() => {
      this.isLoadingDoctorDetailsData = value;
    });
  };
  setIsLoadingAppointmentList = (value = false) => {
    runInAction(() => {
      this.isLoadingAppointmentList = value;
    });
  };
  setIsDeleteUserLoading = (value = false) => {
    runInAction(() => {
      this.isDeleteUserLoading = value;
    });
  };
}

export default DoctorStore;
