import { GET_DASHBOARD_DATA, GET_RECENT_ADDED_DOCTORS } from "constants/ApiConstant";
import moment from "moment";
import { getRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

const { makeAutoObservable, runInAction } = require("mobx");

class DashboardStore {

  initialDashboardStatistics = [
    {
      totalDoctors: 0,
      totalPatients: 0,
      totalAppointments: 0,
      totalRevenue: []
    },
  ];
  dashboardData = this.initialDashboardStatistics;
  recentAddedDoctors = [];
  isLoadingDashboardData = true;

  dashboardYear = moment()

  constructor() {
    makeAutoObservable(this);
  }

  getDashboardData = async (year) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: `${GET_DASHBOARD_DATA}/${year}` });
        const { data, error: responseError } = getValidDataFromResponse({ response, isWarning: true });

        if (!error && !responseError && isValidArray(data)) {
          this.setDashboardData(data);
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };
  getRecentAddedDoctors = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: GET_RECENT_ADDED_DOCTORS });
        const { data, error: responseError } = getValidDataFromResponse({ response, isWarning: true });

        if (!error && !responseError && isValidArray(data)) {
          this.setRecentAddedDoctors(data);
          return resolve({ success: true, data: data });
        }

        return resolve({ success: false, data: [] });
      } catch (error) {
        return reject({ success: false, data: [] });
      }
    });
  };

  setDashboardData = (data = []) => {
    runInAction(() => {
      this.dashboardData = data;
    });
  };
  setRecentAddedDoctors = (data = []) => {
    runInAction(() => {
      this.recentAddedDoctors = data;
    });
  };

  setIsLoadingDashboardData = (value = false) => {
    runInAction(() => {
      this.isLoadingDashboardData = value;
    });
  };

  setDashboardYear = (year = moment()) => {
    runInAction(() => {
      this.dashboardYear = year
    })
  }
}

export default DashboardStore;
