import { GET_PAYMENT_PARTNERS, GET_PLATFORM_CHARGE, UPDATE_PAYMENT_PARTNERS, UPDATE_PLATFORM_FEE } from "constants/ApiConstant";
import { deleteRequest, getRequest, patchRequest, postRequest } from "utils/api";
import { getValidDataFromResponse, isValidArray } from "utils/utilities";

const { makeAutoObservable, runInAction, toJS } = require("mobx");

class AdminSettingStore {
  paymentPartners = [];
  PlatformCharge = [];

  isLoadingForGetPaymentOption = true;
  isLoadingForUpdatePaymentOption = false;

  isLoadingForGetPlatformCharge = true;
  isLoadingForUpdatePlatformCharge = false;

  constructor() {
    makeAutoObservable(this);
  }

  getPaymentPartners = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: GET_PAYMENT_PARTNERS });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidArray(data)) {
          const [{ _id, paypal, stripe } = {}] = data;
          // this.setInitialPaymentOption({ ...this.initialPaymentOption, paypal: paypal, stripe: stripe, _id: _id });
          this.setPaymentPartners(data);
          return resolve({ success: true, data: data });
        }
        this.setPaymentPartners();
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setPaymentPartners();
        return reject({ success: false, data: [] });
      }
    });
  };
  updatePaymentPartners = async (paymentOption) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await patchRequest({
          url: UPDATE_PAYMENT_PARTNERS,
          payload: paymentOption,
        });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError) {
          this.setPaymentPartners(data);
          return resolve({ success: true, data: data });
        }
        // this.setPaymentPartners();
        return resolve({ success: false, data: [] });
      } catch (error) {
        // this.setPaymentPartners();
        return reject({ success: false, data: [] });
      }
    });
  };

  // platform charge

  getPlatformCharge = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await getRequest({ url: GET_PLATFORM_CHARGE });
        const { data, error: responseError } = getValidDataFromResponse({ response });

        if (!error && !responseError && isValidArray(data)) {
          const [{ _id, paypal, stripe } = {}] = data;
          // this.setInitialPaymentOption({ ...this.initialPaymentOption, paypal: paypal, stripe: stripe, _id: _id });
          this.setPlatformCharge(data);
          return resolve({ success: true, data: data });
        }
        this.setPlatformCharge();
        return resolve({ success: false, data: [] });
      } catch (error) {
        this.setPlatformCharge();
        return reject({ success: false, data: [] });
      }
    });
  };

  updatePlatformCharge = async (id, paymentOption) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { error, response } = await patchRequest({
          url: `${UPDATE_PLATFORM_FEE}/${id}`,
          payload: paymentOption,
        });
        const { data, error: responseError } = getValidDataFromResponse({ response, isRequired: true });

        if (!error && !responseError && isValidArray(data)) {
          this.setPlatformCharge(data);
          return resolve({ success: true, data: data });
        }
        // this.setPlatformCharge();
        return resolve({ success: false, data: [] });
      } catch (error) {
        // this.setPlatformCharge();
        return reject({ success: false, data: [] });
      }
    });
  };

  setPaymentPartners = (value = []) => {
    runInAction(() => {
      this.paymentPartners = value;
    });
  };

  setPlatformCharge = (value = []) => {
    runInAction(() => {
      this.PlatformCharge = value;
    });
  };
  // setInitialPaymentOption = (value = {}) => {
  //   runInAction(() => {
  //     this.initialPaymentOption = value;
  //   });
  // };

  setIsLoadingForGetPaymentOption = (value = false) => {
    runInAction(() => {
      this.isLoadingForGetPaymentOption = value;
    });
  };
  setIsLoadingForGetPlatformCharge = (value = false) => {
    runInAction(() => {
      this.isLoadingForGetPlatformCharge = value;
    });
  };
  setIsLoadingForUpdatePlatformCharge = (value = false) => {
    runInAction(() => {
      this.isLoadingForUpdatePlatformCharge = value;
    });
  };
  setIsLoadingForUpdatePaymentOption = (value = false) => {
    runInAction(() => {
      this.isLoadingForUpdatePaymentOption = value;
    });
  };
}

export default AdminSettingStore;
